
import { createPopper } from '@popperjs/core'
import { Portal } from 'portal-vue'
import ClickOutside from '@/vendor/vue-click-outside'

import dateRange from '~/mixins/date-range'

export default {
  components: {
    Portal
  },

  directives: {
    ClickOutside
  },

  mixins: [dateRange],

  props: {
    dates: {
      type: Object,
      default: null
    },

    openCalendar: {
      type: Boolean,
      default: false
    },

    showFormButtons: {
      type: Boolean,
      default: false
    },

    preventOverlayControlScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showForm: false,
      forceOpen: false,
      datesModel: this.dates,
      popperInstance: null
    }
  },

  computed: {
    shouldShowMidweekBanner() {
      return this.$experiment.isEnabled('rvz19720MidweekCalendarExperiment')
    }
  },

  watch: {
    dates: {
      handler: function (newVal) {
        this.datesModel = newVal
      },

      deep: true
    },

    openCalendar: function (newVal) {
      if (newVal) {
        this.forceOpen = true
        this.toggle()
        setTimeout(() => {
          this.forceOpen = false
        }, 250)
      }
    }
  },

  beforeDestroy() {
    this.popperInstance?.destroy()
  },

  methods: {
    initPopper() {
      if (this.popperInstance) return

      this.popperInstance = createPopper(this.$refs.buttonEl, this.$refs.floatingEl, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: () => {
                return [0, 8]
              }
            }
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true
            }
          }
        ]
      })
    },

    toggle() {
      this.showForm = !this.showForm

      if (this.$mediaQueries.isMediumBreakpoint && this.showForm) {
        if (this.popperInstance) {
          this.popperInstance.update()
        } else {
          this.initPopper()
        }
      }

      if (!this.showForm) {
        this.$emit('close')
      }
    },

    close() {
      this.showForm = false
      this.$emit('close')
    },

    updateDates(dates, emitUpdate = false) {
      this.datesModel = dates

      if (!emitUpdate) {
        return
      }
      this.$emit('update:dates', dates)

      if (dates.start && dates.end) {
        this.close()
      }
    },

    apply({ dates }) {
      this.$emit('apply', { dates })
      this.close()
    },

    clear() {
      this.$emit('clear')
      this.close()
    },

    clickOutsideAction(event) {
      if (!this.$mediaQueries.isMediumBreakpoint || !this.showForm || this.forceOpen) {
        return
      }
      if (!event.composedPath().includes(this.$refs.buttonEl)) {
        this.toggle()
      }
    }
  }
}
