
import { DateRangeType } from '@/lib/enums'
import { sortMomentDateRange } from '@/lib/dates'

export default {
  data() {
    return {
      selecting: DateRangeType.START,
      tempStart: null,
      tempEnd: null
    }
  },

  computed: {
    hasStartAndEnd() {
      return Boolean(this.tempStart && this.tempEnd)
    }
  },

  methods: {
    clearTemp(freezeStartDate = false) {
      // Except if start date if frozen
      if (!freezeStartDate) {
        this.tempStart = null
      }
      this.tempEnd = null
    },

    updateTemp(date, freezeStartDate = false) {
      if (this.selecting === DateRangeType.END) {
        // Check if date is already taken
        if (this.tempStart?.isSame(date)) {
          return
        }
        // Update end date
        this.tempEnd = date

        // Update selecting field if start date isn't frozen
        if (!freezeStartDate) {
          this.selecting = DateRangeType.START
        }
      } else {
        // Check if date is already taken
        if (this.tempEnd?.isSame(date)) {
          return
        }

        // Update start date
        this.tempStart = date

        // Update selecting field
        this.selecting = DateRangeType.END
      }

      // Sort dates if both are filled
      if (this.hasStartAndEnd) {
        ;[this.tempStart, this.tempEnd] = sortMomentDateRange([this.tempStart, this.tempEnd])
      }
    }
  }
}
