
import { Portal } from 'portal-vue'
import ClickOutside from '@/vendor/vue-click-outside'

export default {
  components: {
    Portal
  },

  directives: {
    ClickOutside
  },

  props: {
    guests: {
      type: Object,
      default: null
    },

    petFriendly: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    openGuestPicker: {
      type: Boolean,
      default: false
    },

    showFormButtons: {
      type: Boolean,
      default: false
    },

    preventOverlayControlScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showForm: false,
      forceOpen: false,
      guestsModel: this.guests,
      petFriendlyModel: this.petFriendly
    }
  },

  computed: {
    labelText() {
      return this.label || this.$t('guests.title')
    }
  },

  watch: {
    openGuestPicker: function (newVal) {
      if (newVal) {
        this.forceOpen = true
        this.toggle()
        setTimeout(() => {
          this.forceOpen = false
        }, 250)
      }
    },

    '$search.parameters.filters.petFriendly': function (newVal) {
      this.petFriendlyModel = newVal
    }
  },

  methods: {
    toggle() {
      this.showForm = !this.showForm
    },

    close() {
      this.showForm = false
    },

    updateGuests(guests, emitUpdate = false) {
      this.guestsModel = guests

      if (!emitUpdate) {
        return
      }
      this.$emit('update', { guests, petFriendly: this.petFriendlyModel })
    },

    updatePetFriendly(petFriendly, emitUpdate = false) {
      this.petFriendlyModel = petFriendly

      if (!emitUpdate) {
        return
      }
      this.$emit('update', { guests: this.guestsModel, petFriendly })
    },

    apply({ guests, petFriendly }) {
      this.$emit('apply', { guests, petFriendly })
      this.close()
    },

    clear() {
      this.$emit('clear')
      this.close()
    },

    clickOutsideAction(event) {
      if (this.forceOpen) {
        return
      }
      if (!event.composedPath().includes(this.$refs.buttonEl)) {
        this.toggle()
      }
    }
  }
}
