
import { SearchTypes, QueryStrings, RVTypeEnumKey } from '~/lib/enums'
import { towable as trailer, drivable as motorized } from '@/assets/data/rvtypes'
import useRouteManager from '~/lib/useRouteManager'

export default {
  name: 'SearchForm',

  props: {
    showSearchButton: {
      type: Boolean,
      default: false
    },

    searchType: {
      type: String,
      default: null
    },

    searchOnChange: {
      type: Boolean,
      default: false
    },

    preventOverlayControlScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      locationModel: this.$search.parameters.location,
      datesModel: this.$search.parameters.dates.dates,
      guestsModel: this.$search.parameters.guests,
      petFriendlyModel: this.$search.parameters.filters.petFriendly,
      showForm: null
    }
  },

  computed: {
    isSearching() {
      return this.getRouteBaseName() === 'rv-search' || this.getRouteBaseName() === 'pre-filtered-search'
    },

    hasActiveDatesParameters() {
      return this.datesModel.start && this.datesModel.end
    },

    hasActiveGuestsParameters() {
      if (this.petFriendlyModel) {
        return true
      }
      return this.guestsModel.adults + this.guestsModel.children > 0
    },

    hasActiveParameters() {
      if (this.$search.parameters.location?.fullName || this.$search.parameters.bounds?.hasBounds) {
        return true
      }

      if (this.hasActiveDatesParameters) {
        return true
      }

      if (this.hasActiveGuestsParameters) {
        return true
      }

      return false
    },

    locationLabelText() {
      switch (this.searchType) {
        case SearchTypes.Drivable:
        case SearchTypes.Towable:
          return this.$t('pickupLocation')

        case SearchTypes.Delivery:
          return this.$t('deliveryLocation')

        default:
          return this.$t('location')
      }
    },

    canUpdateSearchParameters() {
      return this.searchOnChange || (!this.showSearchButton && this.isSearching)
    },

    hasSearchType() {
      return Boolean(this.searchType)
    },

    isHomeOrPoiPage() {
      return ['index', 'point-of-interest'].includes(this.getRouteBaseName())
    }
  },

  watch: {
    '$search.parameters.location': {
      handler: function (newVal) {
        this.locationModel = newVal
      },

      deep: true
    },

    '$search.parameters.dates': {
      handler: function ({ dates }) {
        this.datesModel = dates
      },

      deep: true
    },

    '$search.parameters.guests': {
      handler: function (newVal) {
        this.guestsModel = newVal
      },

      deep: true
    },

    '$search.parameters.filters.petFriendly': {
      handler: function (newVal) {
        this.petFriendlyModel = newVal
      }
    }
  },

  mounted() {
    if (this.isHomeOrPoiPage && this.hasSearchType) {
      this.$experiment.trackView({ experimentKey: 'rvz18749DiscountDisplayFirstTime' })
    }
  },

  methods: {
    updateRoute() {
      if (this.searchOnChange && !this.isSearching) {
        this.$search.updateRoute(this.localePath('rv-search'))
        return
      }
    },

    updateLocation(location) {
      if (this.showSearchButton) {
        this.locationModel = this.$search.parseGooglePlace(location)

        // Open next field if not filled
        if (!this.hasActiveDatesParameters) {
          this.showForm = 'dates'
        } else if (!this.hasActiveGuestsParameters) {
          this.showForm = 'guests'
        }
      }
    },

    async applyLocation(location) {
      this.locationModel = this.$search.parseGooglePlace(location)

      if (this.canUpdateSearchParameters) {
        await this.$search.updateParameters({ location: this.locationModel })
      }

      this.updateRoute()

      // Open next field if not filled
      if (!this.hasActiveDatesParameters) {
        this.showForm = 'dates'
      } else if (!this.hasActiveGuestsParameters) {
        this.showForm = 'guests'
      }
    },

    clearLocation() {
      this.locationModel = {}

      if (this.canUpdateSearchParameters) {
        this.$search.clearLocation()
      }
    },

    updateDates(dates) {
      if (this.showSearchButton) {
        this.datesModel = dates

        // Open next field if not filled
        if (this.datesModel.start && this.datesModel.end && !this.hasActiveGuestsParameters) {
          this.showForm = 'guests'
        }
      }
    },

    applyDates({ dates }) {
      this.datesModel = dates

      if (this.canUpdateSearchParameters) {
        this.$search.updateDates(dates)
      }

      this.updateRoute()

      // Open next field if not filled
      if (!this.hasActiveGuestsParameters) {
        this.showForm = 'guests'
      }
    },

    resetDatesModel() {
      this.datesModel = {
        start: null,
        end: null
      }
    },

    clearDates() {
      this.resetDatesModel()

      if (this.canUpdateSearchParameters) {
        this.$search.updateDates({})
      }
    },

    updateGuests({ guests, petFriendly }) {
      if (this.showSearchButton) {
        this.guestsModel = guests
        this.petFriendlyModel = petFriendly
      }
    },

    applyGuests({ guests, petFriendly }) {
      this.guestsModel = guests
      this.petFriendlyModel = petFriendly

      if (this.canUpdateSearchParameters) {
        this.$search.updateGuests({ ...guests, petFriendly })
      }

      this.updateRoute()
    },

    clearGuests() {
      this.guestsModel = {
        adults: null,
        children: null,
        pets: false
      }

      this.petFriendlyModel = false

      if (this.canUpdateSearchParameters) {
        this.$search.updateGuests({})
      }
    },

    async submit() {
      if (!this.isSearching) {
        let types = []
        if (this.searchType === SearchTypes.Drivable) {
          types = [
            RVTypeEnumKey.ClassA,
            RVTypeEnumKey.ClassB,
            RVTypeEnumKey.ClassC,
            RVTypeEnumKey.Campervan,
            RVTypeEnumKey.TruckCamper
          ]
        } else if (this.searchType === SearchTypes.Towable) {
          types = [
            RVTypeEnumKey.FifthWheel,
            RVTypeEnumKey.Hybrid,
            RVTypeEnumKey.MicroTrailer,
            'SubTravelTrailer',
            RVTypeEnumKey.TentTrailer,
            RVTypeEnumKey.ToyHauler
          ]
        }

        const newQuery = {
          [QueryStrings.searchAddress]: this.locationModel?.fullName || undefined,
          [QueryStrings.startDate]: this.datesModel?.start || undefined,
          [QueryStrings.endDate]: this.datesModel?.end || undefined,
          [QueryStrings.adults]: this.guestsModel?.adults || undefined,
          [QueryStrings.children]: this.guestsModel?.children || undefined,
          [QueryStrings.types]: types?.join(',') || undefined
        }

        if (this.petFriendlyModel) {
          newQuery[QueryStrings.petFriendly] = null
        }

        if (this.searchType === SearchTypes.Delivery) {
          newQuery[QueryStrings.delivery] = null
        }

        const searchPath = this.localePath('rv-search')

        const { shouldRedirectToNuxt3App, nuxt3BaseUrl } = useRouteManager({
          toPath: searchPath,
          $config: this.$config,
          $experiment: this.$experiment
        })

        this.$emit('submit')

        if (shouldRedirectToNuxt3App) {
          const filtered = Object.keys(newQuery)
            .filter((x) => newQuery[x] !== undefined)
            .reduce((acc, x) => {
              acc[x] = newQuery[x] === null ? newQuery[x] : String(newQuery[x])
              return acc
            }, {})

          const url = new URL(`${nuxt3BaseUrl}${searchPath}?${new URLSearchParams(filtered)}`)
          window.location.href = url.toString()
        } else {
          this.$router.push({
            path: searchPath,
            query: newQuery
          })
        }

        return
      }

      let filters = null

      if (this.searchType) {
        let drivable = undefined
        let towable = undefined

        if (this.searchType === SearchTypes.Drivable) {
          drivable = {}
          motorized.forEach((rvType) => {
            drivable[rvType] = true
          })
        }
        if (this.searchType === SearchTypes.Towable) {
          towable = {}
          trailer.forEach((rvType) => {
            towable[rvType] = true
          })
        }

        filters = {
          delivery: this.searchType === SearchTypes.Delivery,
          drivable,
          towable
        }
      }

      this.$search.updateParameters({
        location: this.locationModel,
        dates: { ...this.datesModel },
        guests: { ...this.guestsModel, petFriendly: this.petFriendlyModel },
        filters
      })

      this.$emit('submit')
    }
  }
}
