import { render, staticRenderFns } from "./toggle.vue?vue&type=template&id=02228b96&scoped=true"
import script from "./toggle.vue?vue&type=script&lang=js"
export * from "./toggle.vue?vue&type=script&lang=js"
import style0 from "./toggle.vue?vue&type=style&index=0&id=02228b96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02228b96",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/search/form/dates.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fsearch%2Fdates%2Ftoggle.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./toggle.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchDatesText: require('/home/vsts/work/1/s/components/search/dates/text.vue').default,BannersMidWeek: require('/home/vsts/work/1/s/components/banners/mid-week.vue').default,OverlayDrawer: require('/home/vsts/work/1/s/components/overlay-drawer.vue').default})
