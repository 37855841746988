
import { Portal } from 'portal-vue'

export default {
  components: {
    Portal
  },

  props: {
    location: {
      type: Object,
      default: null
    },

    labelText: {
      type: String,
      default: null
    },

    withSearchButton: {
      type: Boolean,
      default: false
    },

    preventOverlayControlScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showForm: false,
      locationModel: this.location
    }
  },

  computed: {
    showField() {
      return this.$mediaQueries.isMediumBreakpoint && this.showForm
    }
  },

  watch: {
    location: function (newVal) {
      this.locationModel = newVal
    }
  },

  methods: {
    toggle() {
      this.showForm = !this.showForm
    },

    close() {
      this.showForm = false
    },

    updateLocation(location) {
      if (this.withSearchButton) {
        this.$emit('update', location)
      } else {
        this.$emit('apply', location)
      }

      if (location) {
        this.close()
      }
    },

    clearLocation() {
      this.$emit('clear')
    }
  }
}
